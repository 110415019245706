import React from 'react'
import { classNames } from '../../utils/classNames'

export enum Level {
    HEADING_1,
    HEADING_2,
    HEADING_3,
    HEADING_4,
    HEADING_5,
    HEADING_6,
}

export enum HeadingVariant {
    WHITE,
    GREEN,
    DARK_GREEN,
    RED,
    BLACK,
    GRAY,
}

interface HeadingProps extends React.HTMLAttributes<HTMLOrSVGElement> {
    tag?: React.ElementType
    level?: Level
    headingVariant: HeadingVariant
    color?: string
    classList?: string
}

const HEADING_TYPE_MAPS: Record<Level, string> = {
    [Level.HEADING_1]: 'text-2.5xl leading-6 font-extrabold',
    [Level.HEADING_2]: 'text-2xl leading-2 font-bold',
    [Level.HEADING_3]: 'text-xl font-medium',
    [Level.HEADING_4]: 'text-lg font-bold leading-5',
    [Level.HEADING_5]: 'text-base font-medium',
    [Level.HEADING_6]: 'text-sm font-medium',
}

const HEADING_VARIANT_MAPS: Record<HeadingVariant, string> = {
    [HeadingVariant.WHITE]: 'text-white',
    [HeadingVariant.GREEN]: 'text-primary',
    [HeadingVariant.DARK_GREEN]: 'text-primary-900',
    [HeadingVariant.RED]: 'text-secondary',
    [HeadingVariant.BLACK]: 'text-black',
    [HeadingVariant.GRAY]: 'text-brand-gray-500',
}

const Heading: React.FC<HeadingProps> = ({
    level,
    headingVariant,
    tag,
    classList,
    children,
    ...rest
}) => {
    if (!level) level = Level.HEADING_1
    if (!tag) tag = 'h1'
    const Tag = tag as React.ElementType
    return (
        <Tag
            {...rest}
            className={classNames(
                HEADING_TYPE_MAPS[level],
                HEADING_VARIANT_MAPS[headingVariant],
                classList,
            )}
        >
            {children}
        </Tag>
    )
}

export default Heading
